body, img {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}
.third {
  width: 33.333%;
  float: right;
}
.sixth {
  width: 16.666%;
  float: left;
}
.twothird {
  width: 66.666%;
  float: right;
}
.smtopbot {
  padding-top: 50px;
  padding-bottom: 50px;
}
.padleft {
  padding-left: 20px;
  padding-right: 10px;
}
.padcenter {
  padding-left: 10px;
  padding-right: 10px;
}
.padright {
  padding-right: 20px;
  padding-left: 10px;
}
a {
  text-decoration: none;
  color: #0066A2;
}
a:hover {
  color: black;
  cursor: pointer;
}
.blue {
  color: #0066A2;
}
p {
  font-size: 20px;
  font-family: "Righteous";
  color:#000;
  text-align: left;
}
h1 {
  color: #0066A2;
  font-size: 50px;
  font-family: "Righteous";
}
h2 {
  font-size: 30px;
  font-family: "Righteous";
}
h3 {
  font-size: 75px;
  font-family: "Righteous";
}
h4 {
  font-size: 25px;
  text-align: right;
  font-family: "Righteous";
}
h5 {
  font-size: 30px;
  text-align: left;
  font-family: "Righteous";
}
.space {
  height:100px;
  clear: both;
}
.spacesm {
  height:50px;
  clear: both;
}
.smspace {
  height:20px;
  clear: both;
}
.lists {
  font-size: 20px;          /* Keep the existing font size */
  font-family: "Righteous"; /* Keep the existing font family */
  color: #000;              /* Keep the existing color */
  text-align: center;       /* Center text alignment */
  
  display: flex;            /* Enable flexbox */
  flex-direction: column;   /* Stack items vertically */
  align-items: center;      /* Center items horizontally */
  margin: 0;                /* Remove default margins */
}

.lists ul {
  padding: 0;               /* Remove default padding from the list */
  list-style-position: inside; /* Make bullet points inside the box */
  text-align: center;       /* Center the text within the list */
}

.navigation-arrows {
  display: flex;
  justify-content: center; /* Center the navigation links */
  align-items: center;
  margin-top: 20px; /* Space above the arrows */
}

.arrow {
  font-size: 20px; /* Style for arrows */
  font-family: "Righteous";
  color: #000;
  text-decoration: none;
  padding: 10px;
}

.left-arrow {
  margin-right: auto; /* Push the left arrow to the left */
}

.right-arrow {
  margin-left: auto; /* Push the right arrow to the right */
}

.center-arrow {
  margin: 0 20px; /* Space on the sides of the centered text */
  font-weight: bold; /* Make it bold */
}

.navigation-arrows, .bottom-all-blogs {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Adjust as needed */
  font-size: 30px; /* Same as lists */
  font-family: "Righteous"; /* Same as lists */
  color: #000; /* Same as lists */
  text-align: center; /* Same as lists */
}

.arrow {
  text-decoration: none; /* Remove underline from links */
  color: #000; /* Change to your desired color */
  transition: color 0.3s; /* Smooth color transition on hover */
}

.arrow:hover, .bottom-all-blogs:hover {
  color: #0066A2; /* Change to your desired hover color */
}

.padd-left-twenty {
padding-left: 20px;
}
.padd-twenty {
padding-left: 20px;
padding-right: 20px;
}
.padd-fifty {
  padding-left: 50px;
  padding-right: 50px;
  text-align: left;
}
.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* This keeps the aspect ratio (16:9) */
  background-color: white; /* Ensure the background is white */
  margin: 42px 0; /* Adjust margin for space above and below */
}

.youtube-video {
  position: absolute;
  top: 0; /* Align to the top of the container */
  left: 0; /* Align to the left of the container */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}


.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

form {
    text-align: left;
    padding:50px;
}

#inputarea {
  width: 100%;
  height: 100px;
  font-size: 25px;
  font-family: "Righteous";
  background-color: rgb(236, 236, 236);
  border: none;
  border-bottom: 2px solid black;
  padding: 10px;
  margin-top:10px;
  margin-bottom: 10px;
}
 
.smallbanner {
  width: 410px;
  margin-top:15px;
  margin-left:30px;
  float: left;
  clear:both;
}

.title {
  clear: both;
  width: 100%;
  padding-top:50px;
  font-size: 50px;
  font-family: "Righteous";
}

.title2 {
  clear: both;
  width: 100%;
  padding-top:100px;
  font-size: 50px;
  font-family: "Righteous";
}

.allblogs {
  width:80%;
  margin-left:10%;
  margin-right:10%;
  margin-top:30px;
  margin-bottom:30px;
  float: left;
  clear:both;
}

.oneblog {
  width:30%;
  margin-left:1.66%;
  margin-right: 1.66%;
  float: left;
}

.blogtitle {
  font-size: 30px;
  font-family: "Righteous";
  margin-bottom:10px;
  margin-top:10px;
}

.blog2ndtitle {
  font-size: 24px;
  font-family: "Righteous";
  color: #000;
  margin-bottom:20px;
}

.secondtitle {
  font-size: 30px;
  font-family: "Righteous";
  color: #0066A2;
  
}

#input {
  width: 100%;
  height: 25px;
  font-size: 30px;
  font-family: "Righteous";
  background-color: rgb(236, 236, 236);
  border: none;
  border-bottom: 2px solid black;
  padding:10px;
  margin-top:0px;
  margin-bottom: 10px;
}
#check {
  margin-top:0px;
}
label {
    font-size: 30px;
    font-family: "Righteous";
}
#error {
  color: red;
  font-family: "Righteous";
  padding-bottom:20px;
  padding-top:0px;
  margin-top:0px;
}
.book-now-button  {
  background: #0066A2;
  color: white; 
  font-family: "Righteous";
  height:60px;
  width: 90%;  
  font: bold 60px;
  border:none;
}
.book-now-button:hover {
  background: white;
  color: black;
  border-style: solid;
  border-color: black;
  border-radius: 1px;
  cursor: pointer;
}

.buttcenter {
  width:33.33%;
  float:left;
}

.buttcenterlog {
  width:50%;
  margin-left:25%;
}

.form-submit-button  {
  background: #0066A2;
  color: white; 
  font-family: "Righteous";
  height: 50px;   
  width: 100px;   
  font: bold 30px;
  border:none;

}

.form-submit-button:hover {
  background: white;
  color: black;
  border-style: solid;
  border-color: black;
  border-radius: 1px;
  cursor: pointer;
}

.form-reset-button {
  background: #0066A2;
  color: white; 
  font-family: "Righteous";
  height: 30px;   
  width: 60px;   
  font: 4px;
  border:none;
  float: right;
}
 
.form-reset-button:hover {
  background: white;
  color: black;
  border-style: solid;
  border-color: black;
  border-radius: .5px;
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

#clabel {
  position: relative;
  padding-left: 30px;
}

#clabel::before {
  content: '';
  background: #0066A2;
  background-size: contain;
  width: 18px;
  height: 18px;
  position:absolute;
  left: 2px;
  top:10px;

  transform: scale(0) rotateZ(180deg);
  transition: all 0.4s ;
}

input[type="checkbox"]:checked + #clabel::before {
  transform: scale(1) rotateZ(0deg);
}

#clabel::after {
  content: '';
  border: 2px solid #000;
  width:16px;
  height: 16px;
  position: absolute;
  left: 2px;
  top: 10px;
}

.backdrop {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
}
.thanks {
  text-align: center;
  margin-top:20%;
  color: #ffff;
}

.imgcontainer {
  position: relative;
  background-color: #0066A2;
  border-style: solid;
  border-width: 2px;
  border-color: black;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  color: white;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.imgcontainer:hover .image {
  opacity: 0.3;
  cursor: pointer;
}

.imgcontainer:hover .middle {
  opacity: 1;
  cursor: pointer;
}
.hide {
  opacity: 0;
  width: 0;
  height: 0;
}

.banner{
  position: relative;
  top: 0;
	left: 0;
}

.bannerimg {
  position: absolute;
  z-index: -20;
  top: 0;
  width:47%;
  margin-left:52%;
}

.bannerlogo {
  position: absolute;
  z-index: -10;
  width:30%;
  margin-top: 0%;
  margin-left:25%;
}

.bannerhex {
  z-index: 40;
}
.iconimg {
  width:70%;
  float:left;
  margin-left: 15%;
}
.menuitem {
  width:16.6666%;
  float:left;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
  
}
.menuiteml {
  width:16.6666%;
  float:left;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
  margin-left:6%
  
}
.menuitemsm, .menuitemsml  {
  width:8.3333%;
  float:left;

  margin-top: 25px;
  margin-bottom: 20px;
  
}
.menuitem :hover {
  cursor: pointer;
  -webkit-text-stroke-width: .1px;
  -webkit-text-stroke-color: white;
  text-shadow: 3px 2px 4px #808080;
}
.serv {
  width:90%;
  margin-left:5%;
}
.servimg {
  width:50%;
  float: left;
  margin-right:5%;
  margin-bottom:5%;
}
.servimgr {
  width:50%;
  float: right;
  margin-left:5%;
  margin-bottom:5%;
}
.servimgv {
  width:50%;
  float: right;
  margin-top: 7%;
  margin-left:5%;
  margin-bottom:5%;
}
.servdes {
  padding-top:3%;
  text-align: left;
}
.insta {
  width:100%;
  height:750px;
}
@media screen and (max-width: 1000px) {
  .third, .twothird {
      width: 100%;
      padding:0;
      margin:0;
      padding-top: 10px;
      margin-bottom: 10px;
  }
  .padd-left-twenty, .padleft, .padcenter, .padright {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
  form {
    padding-left: 30px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 1200px) {
  h1 {
    font-size: 40px;
  }
  .sixth {
    width: 33.33%;
    margin-bottom:30px;
  }
}
@media screen and (max-width: 900px) {
  .sixth {
    width: 50%;
    margin-bottom:30px;
  
  }
  .oneblog {
    width: 45%;
    margin-left: 2.5%;
  }
}
@media screen and (max-width: 600px) {
  .buttcenter {
    width:70%;
    float:left;
  }
  .title {
    clear: both;
    width: 100%;
    padding-top:50px;
    font-size: 50px;
    font-family: "Righteous";
  }
  
  .title2 {
    clear: both;
    width: 100%;
    padding-top:100px;
    font-size: 50px;
    font-family: "Righteous";
  }
  .sixth {
    width: 100%;
    margin-bottom:30px;
  }
  .interested {
    width: 50%;
    float: left;
    margin-bottom: 20px;
  }
  h1, h2 {
    font-size: 25px;
  }
  .servimg, .servimgr, .servimgv   {
    width:100%;
    margin-bottom:50px;
  }
  .oneblog {
    width:100%;
    float: left;
    margin:none;
    padding: none;
  }
}

@media screen and (max-width: 400px) {
  .menuitem, .menuiteml {
    width:25%;
    margin-left:0;
  }
  .menuitemsm {
    width:20%;
    margin-top:0;
  }
  .menuitemsml {
    width:20%;
    margin-top:0;
    margin-left:60%;
  }

  .smallbanner{
    width:90%;
    margin-left:5%;
  }
  .title {
    clear: both;
    width: 100%;
    padding-top:40px;
    font-size: 50px;
    font-family: "Righteous";
  }
  
  .allblogs {
    width:80%;
    margin-left:10%;
    margin-right:10%;
    margin-top:30px;
    margin-bottom:30px;
    float: left;
    clear:both;
  }
  
 
  
  .blogtitle {
    font-size: 40px;
    font-family: "Righteous";
    color: #000;
    margin-bottom:10px;
    margin-top:10px;
  }

  
}

/* Main navbar container */
.navbar {
  display: flex;
  justify-content: space-between; /* Keeps logo on the left and links on the right */
  align-items: center;
  padding: 10px 20px;
  background-color: #fff; /* Adjust as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Logo styling */
.navbar .logo img {
  max-width: 300px; /* Logo size */
  height: auto;
}

/* Links on the right */
.nav-links {
  display: flex;
  justify-content: center; /* Center the links */
  align-items: center; /* Center the links vertically */
  list-style: none;
  gap: 20px; /* Space between links */
  flex-wrap: nowrap; /* Ensure links stay on one line unless wrapping */
  margin-left: auto; /* Push links to the right when in desktop view */
}

.nav-links li a {
  font-family: "Righteous";
  text-decoration: none;
  color: #333; /* Adjust link color */
  font-size: 1rem;
  padding: 10px;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: #0066A2; /* Link hover color */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
      flex-direction: column; /* Stack logo and links vertically */
      align-items: center; /* Center items */
      padding: 10px; /* Adjust padding for smaller screens */
  }

  /* Center logo and keep links centered */
  .nav-links {
      flex-direction: row; /* Keep links in a row */
      justify-content: center; /* Center the links */
      margin-top: 10px; /* Space above links */
      width: 100%; /* Take full width for proper centering */
      padding: 0; /* Remove default padding */
  }

  /* Center align the list items */
  .nav-links li {
      text-align: center; /* Center align list items */
  }
}

@media (max-width: 480px) {
  /* Further adjust for small screens */
  .navbar .logo img {
      max-width: 400px; /* Shrink logo on small devices */
  }

  .nav-links li a {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
      padding: 8px;
  }
}

.slideshow-container {
  width: 100%;
  height: 100vh; /* Full height */
  position: relative;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: 100%; /* Full height */
  width: 100%; /* Full width */
  position: relative; /* Allows absolute positioning for .slide-content */
}

.slide-image {
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
}

.slide-content {
  position: absolute; /* Layers the content over the image */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  
  display: flex;
  flex-direction: column; /* Ensures vertical stacking of elements */
  align-items: center; /* Centers elements horizontally */
  justify-content: center; /* Centers elements vertically */

  text-align: center;
  color: rgb(1, 1, 1); /* Text color */
  
  padding: 20px;
  border-radius: 8px;

  /* Add outline effect to the text */
  text-shadow: 
    -1px -1px 0 #e2e0e0,  
     1px -1px 0 #e2e0e0,
    -1px  1px 0 #fff,
     1px  1px 0 #fff;
}

.slide-title {
  font-size: 3em; /* Title size */
  margin: 0 0 10px 0; /* Space below title */
}

.slide-description {
  font-size: 1.6em; /* Description size */
  margin: 10px 0 20px 0; /* Adds space above and below */
}

.book-button {
  background-color: #f39c12; /* Button color */
  color: white; /* Text color */
  border: none; /* Removes default border */
  padding: 10px 20px; /* Adds padding */
  border-radius: 5px; /* Rounds the corners */
  cursor: pointer; /* Changes cursor on hover */
  font-size: 1em; /* Button text size */
  text-align: center; /* Centers text within the button */
}

.book-button:hover {
  background-color: #e67e22; /* Darker shade on hover */
}

.services-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns by default */
  gap: 20px; /* Spacing between columns */
   /* Padding around the services section */
}

.service-column {
  display: flex;
  flex-direction: column; /* Stack image above text */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text */
  background-color: #f9f9f9; /* Optional background color for contrast */
  border-radius: 8px; /* Optional rounded corners */
  padding: 10%; /* Padding inside each service column */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.service-column img {
  width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
  max-width: 300px; /* Limit maximum image width */
  margin-bottom: 10px; /* Space between image and text */
}

@media (max-width: 1024px) {
  .services-container {
      grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }
}

@media (max-width: 600px) {
  .services-container {
      grid-template-columns: 1fr; /* One column on small screens */
  }
}

  @media (max-width: 700px) {
    .slide-content{

      width:80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  
  display: flex;
  flex-direction: column; /* Ensures vertical stacking of elements */
  align-items: center; /* Centers elements horizontally */
  justify-content: center; /* Centers elements vertically */
      
    }
    .slide-title {
      font-size: 2em; /* Title size */
      margin: 0 0 10px 0; /* Space below title */
    }
    
    .slide-description {
      font-size: 1em; /* Description size */
      margin: 10px 0 20px 0; /* Adds space above and below */
      width:79%;
    }
  
}

.youtube-video-container {
  width: 100%;
}

.react-player {
  width: 100%; /* Make the ReactPlayer full width */
  height: auto; /* Maintain aspect ratio */
}

.jordan-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 100px;
}

.jordan-content {
  flex: 1;
  text-align: left;
  padding-left: 50px;
}

.jordan-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
}

@media (max-width: 850px) {
  .jordan-content {
      padding-left: 0;
  }
  .jordan-section {
      flex-direction: column;
      text-align: center;
  }
  

  .jordan-image img {
      max-width: 100%;
  }
}

.space {
  padding-top: 100px;
  padding-bottom: 100px;
}


